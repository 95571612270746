<template>
  <div class="pad-t-3">
    <div class="bb-container bb-container--lista">
      <div class="ml-header">
        <div class="ml-header__avatar">
          <img
            v-if="list.photo"
            :src="$getUserImgUrl(list.photo)"
            width="150"
            height="150"
            sizes="(max-width: 991px) 100vw, 50px"
            alt
            class="ml-header__avatar-picture"
          />
          <img
            v-else
            src="../../../assets/images/embarazada.jpg"
            width="150"
            height="150"
            srcset="
              ../../../assets/images/embarazada-p-500.jpeg   500w,
              ../../../assets/images/embarazada-p-800.jpeg   800w,
              ../../../assets/images/embarazada-p-1080.jpeg 1080w,
              ../../../assets/images/embarazada.jpg         1314w
            "
            sizes="(max-width: 991px) 100vw, 50px"
            alt
            class="ml-header__avatar-picture"
          />
        </div>
        <div class="ml-header__content">
          <div class="ml-header__title">
            {{ listName }}
          </div>
          <div class="ml-header__timer">
            {{ babyChildText }}
          </div>
        </div>
        <div class="ml-header__actions">
          <button class="button button--sm button--light button--ico" @click.prevent="preview()">
            <img src="../../../assets/img/ico/ico-preview.svg" alt="" />
            {{ $t("mylist.preview") }}
          </button>
          <ShareListHucha
            ref="share"
            class="button button--sm"
            key="share1"
          />

        </div>
      </div>

      <div class="ml-objetos" v-if="showSummary">
        <div ref="addButton" class="ml-objetos__actions">
          <a @click.prevent="$router.push({ name: 'list-constructor' })">
            <button class="button button--primary button--block button--ico">
              <img src="../../../assets/img/ico/ico-add.svg" alt="" /> {{$t("generic.add")}}
            </button>
          </a>
        </div>
        <div class="ml-objetos__stats">
          <div class="ml-objetos-stats">
            <div class="ml-objetos-stats__item">
              <span class="ml-objetos-stats__number">
                {{ numOfproducts }}
              </span>
              <span class="ml-objetos-stats__title"> {{ $t("mylist.objectsInYourList") }}</span>
            </div>
            <div class="ml-objetos-stats__item">
              <span class="ml-objetos-stats__number">
                {{ bookedProducts }}
              </span>
              <span class="ml-objetos-stats__title"> {{$t("mylist.boughtObjects")}}</span>
            </div>
            <div class="ml-objetos-stats__item">
              <span class="ml-objetos-stats__number">
                {{ giftedProducts }}
              </span>
              <span class="ml-objetos-stats__title"> {{$t("mylist.reservedObjects")}}</span>
            </div>
            <div class="ml-objetos-stats__item">
              <span class="ml-objetos-stats__number">
                {{ totalPrice }}
              </span>
              <span class="ml-objetos-stats__title"> {{$t("mylist.totalListPrice")}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <no-ssr>
      <portal-target name="sharelist"></portal-target>
    </no-ssr>
    <modal-dialog
      :show="showPreview"
      @close="showPreview = false"
      target-class="modal__card--iframe"
    >
      <div class="modal-share">
        <div class="modal-share__header">
          <div class="modal-share__left">
            <h2 class="modal-share__title">{{ $t("mylist.preview") }}</h2>
            <div class="modal-share__description">
              Así es como los demás verán tu lista
            </div>
          </div>
          <div class="modal-share__actions">
            <share-list key="share2" :sharing="false" @share="share()" />
          </div>
        </div>

        <div class="modal-share__iframe-container">
          <iframe
            id="inlineFrameExample"
            class="modal-share__iframe"
            title="Inline Frame Example"
            width="100%"
            height="auto"
            :src="guestListUrl"
          >
          </iframe>
        </div>
      </div>
    </modal-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ModalDialog from "../../../components/ui/ModalDialog";
import ShareList from "./ShareList";
import ShareListHucha from "./ShareListHucha";
import NoSSR from "vue-no-ssr";
import moment from "moment";
// eslint-disable-next-line no-unused-vars
import moment_es from "moment/locale/es";
import HasAddButtonObserver from "./Mixins/HasAddButtonObserver"; //https://github.com/w3c/IntersectionObserver/tree/master/polyfill

moment.locale("moment_es");

export default {
  name: "ListHeader",
  mixins: [HasAddButtonObserver],
  components: {
    ModalDialog,
    ShareList,
    ShareListHucha,
    "no-ssr": NoSSR,
  },
  props: {
    showSummary: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      copied: false,
      showTooltip: false,
      showPreview: false,
    };
  },
  computed: {
    ...mapGetters({
      list: "ownerList",
      user: "user",
      currency: "loggedInUserCurrency",
    }),
    guestListUrl() {
      return this.$utils.removeDoubleSlashes(
        process.env.APP_URL + "/guest/" + this.list.id + "?preview=true"
      );
    },
    noBabyName() {
      return (
        this.user?.childName === "TBD" ||
        this.user?.childName === null ||
        this.user?.childName === undefined ||
        this.user?.childName === ""
      );
    },
    babyName() {
      return this.noBabyName ? "el bebé" : this.user?.childName;
    },
    noChildBirthday() {
      return (
        this.user?.childBirthday === "TBD" ||
        this.user?.childBirthday === null ||
        this.user?.childBirthday === undefined ||
        this.user?.childBirthday === ""
      );
    },
    babyChildText() {
      return (
        //necesito formatear el texto con el nombre del bebé --> "Quedan {days} días para que llegue {name}."
        this.$t("mylist.babyCountdownWithName", {
          days: this.numberOfDays,
          name: this.babyName,
        })
        //"Quedan " + this.numberOfDays + " días para que llegue " + this.babyName + "."
      );
    },
    numberOfDays() {
      return this.noChildBirthday ? "?" : this.daysRemaining(this.user?.childBirthday);
    },
    listName() {
      return this.list.title !== "" || this.list.title != null
        ? this.list.title
        : "Lista de " + this.list.ownerName;
    },
    numOfproducts() {
      return this.list.products?.length;
    },
    bookedProducts() {
      return this.list.products?.filter((product) => product.status === 2)?.length;
    },
    giftedProducts() {
      return this.list.products?.filter((product) => product.status === 3)?.length;
    },
    totalPrice() {
      return this.$utils.formatMoney(
        this.list.products?.reduce(
          (accumulator, product) => accumulator + product.price,
          0
        ),
        this.currency
      );
    },
  },
  methods: {
    daysRemaining(date) {
      const eventDate = moment(date);
      const todaysDate = moment();
      const diff = eventDate.diff(todaysDate, "days") + 1;
      return diff > 0 ? diff : 0;
    },
    share() {
      this.showPreview = false;
      this.$sleep(() => this.$refs.share.share(), 100);
    },
    preview() {
      //Show vista previa modal version
      // this.showPreview = true;
      // return
      //Show vista previa new page version
      this.$router.push({ name: "guest", params: { id: this.list.id }, query: { vista: 'invitado' }})
    }

  },
};
</script>
