export const banners = {
  4: {
    tag: "¡Novedades!",
    title: "Unas pocas ideas para empezar tu lista con buen pie",
    cover1: "banner-000-buit.jpg", //imatge del CTA no modal
    color1: "blue",   // Color del badge al CTA
    ModalColor: "blue",
    ModalCover: "banner-000-buit.jpg",
    ModalColorBadge: "blue",
    ModalClose: "dark",
  },
  5: {
    tag: "¡Novedades!",
    title: "Simplemente lo mejor: lo más añadido por nuestros usuarios a sus listas",
    cover1: "banner-005-objetos-calidad.jpg",
    color1: "pink",
    ModalColor: "indigo",
    ModalCover: "cta-modal-lomejor.jpg",
    ModalColorBadge: "light-indigo",
  },
  6: {
    tag: "¡Novedades!",
    title: "Lo mejor para portear",
    cover1: "banner-002-portear.jpg",
    color1: "primary",
    ModalColor: "yellow",
    ModalCover: "cta-modal-portear.jpg",
    ModalColorBadge: "primary-dark",
  },
  7: {
    tag: "¡Novedades!",
    title: "Arrullando que es gerundio",
    cover1: "banner-003-arrullando.jpg",
    color1: "blue",
    ModalColor: "blue",
    ModalCover: "cta-modal-arrullando.jpg",
    ModalColorBadge: "blue",
    ModalClose: "dark",
  },
  8: {
    tag: "¡Novedades!",
    title: "Del hospital a casa: las mejores sillas de coche",
    cover1: "banner-004-sillas.jpg",
    color1: "accent",
    ModalColor: "indigo",
    ModalCover: "cta-modal-sillas.jpg",
    ModalColorBadge: "light-indigo",
    ModalClose: "light",
  },
  9: {
    tag: "¡Novedades!",
    title: "Objetos de calidad que seguro te duran para el siguiente bebé ;)",
    cover1: "banner-005-objetos-calidad.jpg",
    color1: "pink",
    ModalCover: "cta-modal-calidad.jpg",
    ModalColor: "pink",
    ModalColorBadge: "pink-dark",
    ModalClose: "dark",
  },
  10: {
    tag: "¡Novedades!",
    title: "Esenciales del baño y cuidados",
    cover1: "banner-006-bano-cuidados.jpg",
    color1: "accent",
    ModalColor: "blue",
    ModalCover: "cta-modal-bano.jpg",
    ModalColorBadge: "blue",
    ModalClose: "dark",
  },
  11: {
    tag: "¡Novedades!",
    title: "Nuestras bolsas pañaleras favoritas",
    cover1: "banner-007-bolsas-panaleras.jpg",
    color1: "primary",
    ModalColor: "yellow",
    ModalCover: "cta-modal-panaleras.jpg",
    ModalColorBadge: "primary-dark",
    ModalClose: "dark",
  },
  12: {
    tag: "¡Novedades!",
    title: "Primeros dulces sueños",
    cover1: "banner-008-primeros-suenos.jpg",
    color1: "blue",
    ModalColor: "indigo",
    ModalCover: "cta-modal-suenos.jpg",
    ModalColorBadge: "light-indigo",
    ModalClose: "light",
  },
  13: {
    tag: "¡Novedades!",
    title: "Todos los chupetes que necesitaréis",
    cover1: "banner-009-chupetes-parar-tren.jpg",
    color1: "blue",
    ModalColor: "pink",
    ModalCover: "cta-modal-chupetes.jpg",
    ModalColorBadge: "pink-dark",
    ModalClose: "dark",
  },
}

export const NAMES = {
  "INICIAL": 4,
  "MAS_ANADIDO": 5,
  "PORTEAR": 6,
  "ARRULLANDO": 7,
  "SILLAS": 8,
  "CALIDAD": 9,
  "CUIDADOS": 10,
  "PANALERAS": 11,
  "SUENOS": 12,
  "CHUPETES": 13,
}