import "intersection-observer";

export default {
  methods: {
    observeAddButton() {
      const observer = new IntersectionObserver((entries) => {
        entries.map((entry) => {
            if (entry.isIntersecting) {
                this.$store.commit("SET_CTA_FIXED_VISIBILITY", true);
            } else {
                this.$store.commit("SET_CTA_FIXED_VISIBILITY", false);
            }
        });
      });
      let options = {
        // root: document.querySelector('#scrollArea'),
        root: null, // Null = Viewport del navegador
        // rootMargin: '0px',
        threshold: 0,
      };
      if (this.$refs.addButton) {
        observer.observe(this.$refs.addButton, options);
      } else {
        console.warn("Could not add observer for `addButton`. The reference was missing, probably hidden by some `v-if`.");
      }
    }
  },
  mounted() {
    this.observeAddButton()
  }
}
