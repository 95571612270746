<template>
  <!--  // TODO David canviar la transició. Esta és la mateixa que a ProductSticky també una secció
  fixed a la fitxa de producte. Per coherència les dos igual? -->
  <transition name="bbUp">
    <section v-if="show" class="cta-modal">
      <div
        class="cta-modal__grid"
        :class="'cta-modal__grid--' + banner.ModalColor"
        style="cursor:pointer;"
        @click="goToConstructor()"
      >
        <div class="cta-modal__cover">
          <img :src="banner.ModalCover" :alt="banner.title" />
        </div>
        <div class="cta-modal__content">
          <div class="cta-modal__header">
            <div class="cta-modal__tag">
              <span
                class="badge badge--sm badge--medium"
                :class="'badge--' + banner.ModalColorBadge"
                v-html="banner.tag"
              >
              </span>
            </div>
            <h4 class="cta-modal__title" v-html="banner.title"></h4>
          </div>
          <div class="cta-modal__actions">
            <button
              class="button button--dark button--sm button--block"
            >
              Explorar
            </button>
          </div>
          <button
            @click.stop="close()"
            class="cta-modal__close"
            :class="'cta-modal__close--' + banner.ModalClose"
          >
            <img src="../../assets/img/ico/ico-close-banner.svg" alt="" />
          </button>
        </div>
      </div>
    </section>
  </transition>
</template>

<script>
// import Cookies from "js-cookie";
import { mapGetters } from "vuex";
import { banners } from "../ownerlist/banners/banners";

export default {
  name: "CtaBannerFixed",
  data() {
    return {
      banner: {},
    };
  },
  computed: {
    ...mapGetters({
      ctaFixedVisibility: "ctaFixedVisibility",
      ctaFixedVisibility2: "ctaFixedVisibility2",
      limit: "limit",
    }),
    show() {
      return !this.ctaFixedVisibility && !this.ctaFixedVisibility2;
    },
  },
  methods: {
    close() {
      window.mixpanel.track("Click banner llista fixed", {
        Name: this.banner.title,
        Tancar: true
      });
      this.$store.commit("SET_CTA_FIXED_VISIBILITY", true);
      this.$emit('close')
      window.localStorage.setItem("cta_fixed_banner_" + this.limit, "hidden")
      //Cookies.set("cta_fixed_banner_" + this.limit, "hidden", {
      //  expires: 365,
      //  secure: false,
      //});
    },
    goToConstructor() {
      window.mixpanel.track("Click banner llista fixed", {
        Name: this.banner.title,
      });
      if(this.$utils.isApp()) window.location.replace(process.env.APP_URL + "constructor#constructor_sections");
      else this.$router.push({ name: "list-constructor", hash: "#constructor_sections" });
    },
  },
  mounted() {
    this.banners = banners;
    if (this.banners) {
        this.banner = this.banners[this.limit];
      }
    else this.banner = {}
  },
};
</script>

<style lang="scss" scoped></style>
